var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "comment-card" },
    [
      _c("div", { staticClass: "comment-header" }, [
        _c("div", { staticClass: "avatar" }, [
          _c("img", {
            attrs: { src: _vm.avatar(), alt: _vm.$t("comment.poster_avatar") },
            on: { error: _vm.avatarImageError },
          }),
        ]),
        _c("div", { staticClass: "profile-detail" }, [
          _c("div", { staticClass: "profile-line1" }, [
            _c("div", { staticClass: "poster-name" }, [
              _c("strong", [_vm._v(_vm._s(_vm.comment.author))]),
            ]),
            _c(
              "div",
              { staticClass: "comment-actions" },
              [
                _vm.canView()
                  ? [
                      _c(
                        "b-button",
                        {
                          staticClass: "comment-action",
                          attrs: { id: "BTN_EDIT_" + _vm.id, size: "sm" },
                          on: { click: _vm.editComment },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "pen"] },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_EDIT_" + _vm.id,
                            triggers: "hover",
                            placement: "left",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                      ),
                    ]
                  : _vm._e(),
                _vm.canDelete()
                  ? [
                      _c(
                        "b-button",
                        {
                          staticClass: "comment-action",
                          attrs: { id: "BTN_DELETE_" + _vm.id, size: "sm" },
                          on: { click: _vm.deleteComment },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "trash-can"] },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_DELETE_" + _vm.id,
                            triggers: "hover",
                            placement: "left",
                            delay: "0",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "profile-line2" }, [
            _vm._v(_vm._s(_vm.getDateString())),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          ref: _vm.id,
          staticClass: "note markdown-body",
          class: { "note-max-height": !_vm.allowReadMore },
          attrs: { id: _vm.id },
        },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) } }),
          _vm.readMoreShow
            ? _c("div", { staticClass: "read-more" }, [
                _c("div", { on: { click: _vm.readMoreClick } }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("comment.link.read_more"))),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("CommentModal", {
        attrs: {
          id: _vm.comment.uuId,
          show: _vm.commentShow,
          comments: _vm.comments,
          title: _vm.$t("comment.edit_comment"),
          submitAction: "Update",
        },
        on: {
          "update:show": function ($event) {
            _vm.commentShow = $event
          },
          success: _vm.commentSuccess,
          reload: _vm.commentSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Confirm Deletion",
            "ok-title": "Confirm",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("comment.confirmation.delete")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }