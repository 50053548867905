var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(" " + _vm._s(_vm.$t("permission.active_projects")) + " "),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("ActiveProjectList", {
              staticClass: "project-list",
              attrs: {
                refresh: _vm.refreshList,
                columns: _vm.profile ? _vm.profile.columns : null,
              },
              on: {
                columnsUpdated: _vm.columnsUpdated,
                editProject: _vm.editProject,
                refreshed: _vm.refreshed,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }