var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "working-card mb-2" },
    [
      _c("div", [_vm._v(_vm._s(_vm.label))]),
      _vm._l(_vm.entry, function (value, index) {
        return [
          value.calendarName == _vm.calendarName || _vm.entry.length == 1
            ? _c(
                "div",
                { key: index },
                [
                  _c(
                    "b-row",
                    { staticClass: "py-1" },
                    [
                      _c("b-col", { attrs: { sm: "" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("Timepicker", {
                              staticClass: "d-block mr-1",
                              attrs: {
                                readOnly:
                                  _vm.readOnly ||
                                  (!_vm.canAdd() &&
                                    (typeof value.uuId === "undefined" ||
                                      value.uuId.includes("EXCEPTION"))) ||
                                  (!_vm.canEdit() &&
                                    value.uuId != null &&
                                    !value.uuId.includes("EXCEPTION")),
                                disableTime:
                                  _vm.readOnly ||
                                  (!_vm.canAdd() &&
                                    (typeof value.uuId === "undefined" ||
                                      value.uuId.includes("EXCEPTION"))) ||
                                  (!_vm.canEdit() &&
                                    value.uuId != null &&
                                    !value.uuId.includes("EXCEPTION")),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.change(value)
                                },
                                context: function ($event) {
                                  return _vm.onContext(
                                    $event,
                                    index,
                                    "startHour"
                                  )
                                },
                              },
                              model: {
                                value: value.startHour,
                                callback: function ($$v) {
                                  _vm.$set(value, "startHour", $$v)
                                },
                                expression: "value.startHour",
                              },
                            }),
                            _c("Timepicker", {
                              staticClass: "d-block mr-1",
                              attrs: {
                                readOnly:
                                  _vm.readOnly ||
                                  (!_vm.canAdd() &&
                                    (typeof value.uuId === "undefined" ||
                                      value.uuId.includes("EXCEPTION"))) ||
                                  (!_vm.canEdit() &&
                                    value.uuId != null &&
                                    !value.uuId.includes("EXCEPTION")),
                                disableTime:
                                  _vm.readOnly ||
                                  (!_vm.canAdd() &&
                                    (typeof value.uuId === "undefined" ||
                                      value.uuId.includes("EXCEPTION"))) ||
                                  (!_vm.canEdit() &&
                                    value.uuId != null &&
                                    !value.uuId.includes("EXCEPTION")),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.change(value)
                                },
                                context: function ($event) {
                                  return _vm.onContext($event, index, "endHour")
                                },
                              },
                              model: {
                                value: value.endHour,
                                callback: function ($$v) {
                                  _vm.$set(value, "endHour", $$v)
                                },
                                expression: "value.endHour",
                              },
                            }),
                            !_vm.readOnly
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "d-block mr-1" },
                                    [
                                      !_vm.readOnly && _vm.canAdd()
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                id:
                                                  "WORKING_ACTION_ADD_" +
                                                  _vm.id +
                                                  "_" +
                                                  index,
                                                variant: "secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addEntry(value)
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "plus"],
                                                },
                                              }),
                                              _c("b-popover", {
                                                attrs: {
                                                  target:
                                                    "WORKING_ACTION_ADD_" +
                                                    _vm.id +
                                                    "_" +
                                                    index,
                                                  placement: "top",
                                                  boundary: "viewport",
                                                  triggers: "hover",
                                                  content: _vm.$t("button.add"),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-block mr-1" },
                                    [
                                      !_vm.readOnly && _vm.canDelete()
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                id:
                                                  "WORKING_ACTION_DELETE_" +
                                                  _vm.id +
                                                  "_" +
                                                  index,
                                                disabled: index === 0,
                                                variant: "secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeEntry(value)
                                                },
                                              },
                                            },
                                            [
                                              _c("b-popover", {
                                                attrs: {
                                                  target:
                                                    "WORKING_ACTION_DELETE_" +
                                                    _vm.id +
                                                    "_" +
                                                    index,
                                                  placement: "top",
                                                  boundary: "viewport",
                                                  triggers: "hover",
                                                  content:
                                                    _vm.$t("button.delete"),
                                                },
                                              }),
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "trash-can"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-block mr-1" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            id:
                                              "WORKING_ACTION_HISTORY_" +
                                              _vm.id +
                                              "_" +
                                              index,
                                            disabled:
                                              !value.uuId ||
                                              value.uuId.startsWith(
                                                "EXCEPTION_"
                                              ),
                                            variant: "secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showHistory(value)
                                            },
                                          },
                                        },
                                        [
                                          _c("b-popover", {
                                            attrs: {
                                              target:
                                                "WORKING_ACTION_HISTORY_" +
                                                _vm.id +
                                                "_" +
                                                index,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t("button.history"),
                                            },
                                          }),
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: [
                                                "far",
                                                "clock-rotate-left",
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _c(
        "b-alert",
        {
          attrs: { variant: "danger", dismissible: "", show: _vm.showError },
          on: { dismissed: _vm.dismissAlert },
        },
        [
          _c("font-awesome-icon", {
            attrs: { icon: ["fas", "triangle-exclamation"] },
          }),
          _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }